import { useRouter } from 'next/router';
import { useEffect } from 'react';
import dynamicRoute from '@/miscellaneous/constant';

/**
 * React functional component for the Index page.
 * @returns {JSX.Element} The rendered JSX element.
 */
const Index = () => {
  /**
   * The Next.js router object.
   * @type {import('next/router').NextRouter}
   */
  const router = useRouter();

  /**
   * React useEffect hook to handle redirection to the login page.
   * It runs once when the component is mounted.
   */
  useEffect(() => {
    // Redirect to the login page when the component mounts.
    router.push(dynamicRoute.login);
  }, []);
  return <p data-sentry-component="Index" data-sentry-source-file="index.tsx" />;
};
export default Index;